import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TimerService } from './timer.service';
import { User } from '../models/user';
import { UserService } from './user.service';

export const authGuard: CanActivateFn = (route, state) => {
  //console.log('ROUTEDATA:', route, state);

  const _userService = inject(UserService);
  const _timerService = inject(TimerService);
  const _jwt = inject(JwtHelperService);
  const _router = inject(Router);

  const _token = _jwt.tokenGetter();
  const _validLogin = _token && !_jwt.isTokenExpired();

  if (!_validLogin) {
    const _existingTimerPopup = window.open('', 'AZESTimer');
    _existingTimerPopup?.close();

    //_webSocketService.connection?.close();
    _userService.user = new User();
    _timerService.resetTimer();
    localStorage.clear();
  }

  switch (route.routeConfig?.path) {
    case 'register':
    case 'forgot-password':
    case 'login':
      return _validLogin
        ? _router.createUrlTree(['/private', 'dashboard'])
        : true;
    case 'imprint':
    case 'privacy':
      return true;
    default:
      return _validLogin ? true : _router.createUrlTree(['/login']);
  }
};
