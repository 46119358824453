<app-public-container title="Datenschutz" [showBackButton]="true">
    <div class="content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex
        quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Vel necessitatibus ipsa consequuntur in nostrum eligendi, ex quas neque, voluptas
        eaque facilis voluptatibus voluptates autem illum? Quia, optio? Ducimus, accusantium nisi?
        Non provident doloremque quam laborum minima molestias consequatur soluta expedita beatae? A at reprehenderit reiciendis animi, eum
        ducimus officiis modi aspernatur facere, harum obcaecati nobis et iste est quo nostrum!
    </div>
</app-public-container>
