import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImprintComponent } from './public/imprint/imprint.component';
import { LoginComponent } from './public/login/login.component';
import { PrivacyComponent } from './public/privacy/privacy.component';
import { TimerComponent } from './components/timer/timer.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { RegisterComponent } from './public/register/register.component';
import { authGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [authGuard],
    component: LoginComponent,
  },
  {
    path: 'register',
    canActivate: [authGuard],
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [authGuard],
    component: ForgotPasswordComponent,
  },
  {
    path: 'imprint',
    canActivate: [authGuard],
    component: ImprintComponent,
  },
  {
    path: 'privacy',
    canActivate: [authGuard],
    component: PrivacyComponent,
  },
  {
    path: 'timer',
    canActivate: [authGuard],
    component: TimerComponent,
  },
  {
    path: 'private',
    loadChildren: () =>
      import('./private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
