import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  connection?: WebSocket;

  constructor(
    private userService: UserService,
    private jwtService: JwtHelperService
  ) {
    //if (userService.user.id) this.connect();
  }

  connect() {
    this.connection = new WebSocket(`${environment.wsUrl}/hula`);

    this.connection.onmessage = (e: any) => {
      console.log(e.data);
      switch (e.data) {
        case 'auth-required':
          {
            const message = {
              action: 'login',
              token: this.jwtService.tokenGetter(),
            };
            this.connection?.send(JSON.stringify(message));
          }
          break;
      }
    };
  }
}
