import { Component } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ImprintComponent
{

}
