<div class="wrapper">

    <div class="public-container">

        <div class="logo-container" routerLink="/" *ngIf="showLogo">
            <div class="logo"></div>
        </div>

        <div class="title">{{title}}</div>

        <ng-content></ng-content>

        <button type="button" *ngIf="showBackButton" style="width:100%" mat-raised-button routerLink="/">Zurück</button>
    </div>

    <div class="footer" *ngIf="showFooter">
        <button type="button" mat-button routerLink="/imprint">Impressum</button>
        <button type="button" mat-button routerLink="/privacy">Datenschutz</button>
    </div>

</div>
