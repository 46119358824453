<div class="timer-wrapper" [ngClass]="{'timer-popup': mode != 'compact'}">
    <div class="timer-info">
        {{['start', 'stop'].includes(timerService.status) ? 'Arbeitszeit' : 'Pausenzeit'}}
        <span [ngClass]="timerService.status">
            {{(['start', 'stop'].includes(timerService.status) ? timerService.workTime : timerService.breakTime) | timer:'noSeconds'}}
        </span>
    </div>

    <div class="toolbar" *ngIf="mode == 'compact'">
        <button name="start" mat-icon-button (click)="timerService.start()" *ngIf="timerService.status !== 'start'">
            <mat-icon>play_arrow</mat-icon>
        </button>

        <button name="pause" mat-icon-button (click)="timerService.pause()" *ngIf="timerService.status === 'start'">
            <mat-icon>pause</mat-icon>
        </button>

        <button name="stop" mat-icon-button (click)="timerService.stop()" *ngIf="timerService.status === 'start'">
            <mat-icon>stop</mat-icon>
        </button>

        <button mat-icon-button (click)="openTimerModal()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </div>

</div>
