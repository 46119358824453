import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class PrivacyComponent
{

}
